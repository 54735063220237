import axios from 'axios';
import { GET_INQUIRE_MSE_POLICY } from '../../constants/api';

// Move functions to util file because it caused mocking issues in jest to have the component file export the names functions.
// These common functions are re-used in separate areas.

export const sendGetMSEMSCPlusPolicyInquiry = async (selectedPolicyNumber, setErrorGetPolicyInquiry, setIsGetPolicyInquiryAlertVisible, setSearchLoading) => {
  let policyNumber;
  try {
    policyNumber = await axios.get(`${GET_INQUIRE_MSE_POLICY}/${selectedPolicyNumber}`);
  } catch (err) {
    setErrorGetPolicyInquiry(err.message);
    setIsGetPolicyInquiryAlertVisible(true);
    setSearchLoading(false);
    policyNumber = err;
  }
  return policyNumber;
};
