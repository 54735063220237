import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';

import { Fade, Modal } from '@material-ui/core';
import {
  Alert,
  Box,
  Button,
  DataGrid,
  DatePicker,
  defaultPaginationData,
  Divider,
  Link,
  Paper,
  Row,
  Select,
  TextField,
  Typography,
} from 'components/AORedesign';
import { RowCol } from 'components/ScreenSmith';
import RowItem from 'components/ScreenSmith/RowItem';
import { API_BASE_URL, GET_INQUIRE_MSS_POLICY } from '../../constants/api';
import { redirectUrlFunction } from '../CommonUtilityServices/RedirectService';
import { sendGetMSEMSCPlusPolicyInquiry } from './cls-utis';

export const MSS_POLICY_INQUIRY = 'Policy Inquiry';
const MSS_VIEW_POLICY_DOCUMENTS = 'View Policy';
const AO_DATEPICKER_INVALID_DATE_VALUE = 'Invalid date';
const MAIL_TO_LINK =
  'mailto:MSACLProcessing@msagroup.com?subject=Commercial Lines Policy Change Request&body=Please provide the required information below to request a commercial policy change request.%0A%0ANamed Insured:%0APolicy number:%0AAgency code:%0A%0AEffective Date of change:%0ADetails for Changes requested:%0A%0A';
const ERROR_MESSAGE = 'Error retrieving policy source. Please reach out to MSA Help Desk';
const SEARCH_TYPE_POLICY_NUMBER = 'policyNumber';
const SEARCH_TYPE_BUSINESS_NAME = 'businessName';
const POLICY_SYSTEM_MSE = 'policy-inquiry-main-street-express';
const defaultSearchResult = { loading: false, data: null };

export const sendGetPolicyInquiry = async (
  _request_body,
  setErrorGetPolicyInquiry,
  setIsGetPolicyInquiryAlertVisible,
  setSearchLoading,
  setViewPolicyDocsLoading,
) => {
  let getPolicyInquiry;
  try {
    getPolicyInquiry = await axios.post(`${GET_INQUIRE_MSS_POLICY}`, _request_body);
  } catch (err) {
    // Handle Error Here
    setErrorGetPolicyInquiry(err.message);
    setIsGetPolicyInquiryAlertVisible(true);
    setSearchLoading(false);
    setViewPolicyDocsLoading(false);
    getPolicyInquiry = err;
  }
  return getPolicyInquiry;
};

export const getMSSPolicyInquiryRequestBody = (policyAsOfDate, inquiryType, selectedPolicyNumber) => ({
  inquiryDate: policyAsOfDate,
  inquiryType: inquiryType,
  policyNumber: selectedPolicyNumber,
});

export const getMSSRedirectRequestBody = (policyInquiryPayload, policyAsOfDate) => ({
  Area: 'Commercial Lines',
  TypeOfRedirect: 'MSS Policy Inquiry',
  DetailedContext: [
    {
      name: 'transactionId',
      value: policyInquiryPayload?.data?.policyInquiryInfo?.systemAssignId,
    },
    {
      name: 'accountType',
      value: policyInquiryPayload?.data?.acctType,
    },
    {
      name: 'effectiveDate',
      value: policyAsOfDate,
    },
  ],
});

export const getMSEMSCPRedirectRequestBody = (clmsePolicyPayload) => ({
  Area: 'Commercial Lines',
  TypeOfRedirect: 'MSE Policy Inquiry',
  DetailedContext: [
    {
      name: 'system',
      value: 'SBSS',
    },
    {
      name: 'policyNumber',
      value: clmsePolicyPayload?.data[0]?.policyNumber,
    },
    {
      name: 'agencyNumber',
      value: clmsePolicyPayload?.data[0]?.agencyId,
    },
    {
      name: 'path',
      value: 'details',
    },
  ],
});

const CLSPolicyInquiry = () => {
  const [selectedPolicyNumber, setSelectedPolicyNumber] = useState('');
  const [errorGetPolicyInquiry, setErrorGetPolicyInquiry] = useState(null);
  const [policyNumberNotFound, setPolicyNumberNotFound] = useState(null);
  const [policyInquiryRedirectError, setPolicyInquiryRedirectError] = useState(null);
  const [policyAsOfDate, setPolicyAsOfDate] = useState(moment().format('MM/DD/YYYY'));
  const [selectedSearchType, setSelectedSearchType] = useState('');
  const [searchInputValue, setSearchInputValue] = useState('');
  const [paginationData, setPaginationData] = useState(defaultPaginationData);
  const [searchResults, setSearchResults] = useState(defaultSearchResult);

  const resetAlerts = () => {
    setErrorGetPolicyInquiry(null);
    setPolicyNumberNotFound(null);
  };

  const resetGrid = () => {
    setSearchResults(defaultSearchResult);
    setPaginationData(defaultPaginationData);
  };

  const getMSSPolicyInfoAndRedirect = (inquiryType) => {
    if (!policyAsOfDate || !selectedPolicyNumber || policyAsOfDate === AO_DATEPICKER_INVALID_DATE_VALUE) {
      let message = '';

      if (!policyAsOfDate && !selectedPolicyNumber) {
        message = 'Enter a policy number and date to view policy.';
      } else if (!selectedPolicyNumber) {
        message = 'Enter policy number and try again.';
      } else if (!policyAsOfDate) {
        message = 'Enter a date and try again.';
      } else if (policyAsOfDate === AO_DATEPICKER_INVALID_DATE_VALUE) {
        message = 'Date is invalid. Check to ensure policy number and date was entered correctly and try again.';
      }
      setSearchResults((prevState) => ({ ...prevState, loading: false }));
      setPolicyNumberNotFound(message);
      return;
    }

    const request_body_for_policy_inquiry = getMSSPolicyInquiryRequestBody(policyAsOfDate, inquiryType, selectedPolicyNumber);

    // Call Proxy Policy Inquiry to get data for request body
    sendGetPolicyInquiry(
      request_body_for_policy_inquiry,
      setErrorGetPolicyInquiry,
      () => {},
      () => {},
      () => {},
    )
      .then((policyInquiryPayload) => {
        // if data is undefined (bad request) break out
        if (!_.isNil(policyInquiryPayload.data)) {
          // if 200 and policyNumber is null or undefined
          if (_.isNil(policyInquiryPayload.data.policyNumber) || _.isNil(policyInquiryPayload.data.acctType)) {
            setPolicyNumberNotFound(policyInquiryPayload.data.details);
            //setSearchResults((prevState) => ({ ...prevState, loading: false }));
          } else {
            const redirect_request_body = getMSSRedirectRequestBody(policyInquiryPayload, policyAsOfDate);

            redirectUrlFunction(redirect_request_body, 'POST', null, setPolicyInquiryRedirectError, () => {});
          }
        }
      })
      .finally(() => {
        setSearchResults((prevState) => ({ ...prevState, loading: false }));
      });
  };

  const getMSEMSCPlusPolicyInfoAndRedirect = async (policy_system, policyNumber) => {
    const clmsePolicyPayload = await sendGetMSEMSCPlusPolicyInquiry(
      policyNumber,
      setErrorGetPolicyInquiry,
      () => {},
      () => {},
    );
    if (!_.isNil(clmsePolicyPayload) && !_.isNil(clmsePolicyPayload.data) && clmsePolicyPayload.data.length > 0) {
      // login to sbss
      const redirect_request_body = getMSEMSCPRedirectRequestBody(clmsePolicyPayload);
      redirectUrlFunction(redirect_request_body, 'POST', null, setPolicyInquiryRedirectError, () => {});
      setSearchResults((prevState) => ({ ...prevState, loading: false }));
    } else {
      // set warning message
      if (policy_system === POLICY_SYSTEM_MSE) {
        setPolicyNumberNotFound('Policy is not available in Main Street Express/Main Street Commercial+. Please modify your search and try again.');
      } else {
        setPolicyNumberNotFound('Policy is not available in Main Street Commercial+. Please modify your search and try again.');
      }
      setSearchResults((prevState) => ({ ...prevState, loading: false }));
    }
    return clmsePolicyPayload;
  };

  const handleSearchInputChange = (event) => {
    resetAlerts();
    const searchInputValue = event.target.value;
    setSearchInputValue(searchInputValue);
  };

  const handleChangeSearchType = (event) => {
    setSelectedSearchType(event.target.value);
    resetGrid();
  };

  const handleOnChangePolicyAsOfDate = (date) => {
    setPolicyAsOfDate(moment(date).format('MM/DD/YYYY'));
  };

  const renderCell = (params) => {
    const handleOnClick = () => {
      resetAlerts();
      if (params.row.sourceSystem === 'T') {
        setSearchResults((prevState) => ({ ...prevState, loading: true }));
        getMSEMSCPlusPolicyInfoAndRedirect(POLICY_SYSTEM_MSE, params.row.policyNumber);
      } else if (params.row.sourceSystem === 'I') {
        setSelectedPolicyNumber(params.row.policyNumber);
      }
    };
    return params.row.policyNumber && <Link onClick={handleOnClick}>{params.row.policyNumber}</Link>;
  };

  const clSearchResultColumns = [
    {
      field: 'policyNumber',
      headerName: 'Policy Number',
      sortable: true,
      flex: 1,
      renderCell,
    },
    {
      field: 'namedInsured',
      headerName: 'Named Insured',
      sortable: true,
      flex: 1,
    },
    {
      field: 'mailingAddress',
      headerName: 'Mailing Address',
      sortable: true,

      flex: 1,
      cellClassName: 'wrap-text',
    },
    {
      field: 'lob',
      headerName: 'LOB',
      flex: 1,
    },
    {
      field: 'agentCode',
      headerName: 'Agent Code',
      flex: 1,
    },

    {
      field: 'sourceSystem',
      header: 'Source System',
      hide: true,
    },
  ];

  const retrieveResults = async () => {
    try {
      setSearchResults((prevState) => ({ ...prevState, loading: true }));
      const results = await axios.get(`${API_BASE_URL}/cl-policy-view-change/search?searchType=${selectedSearchType}&searchValue=${searchInputValue}`);
      const searchByPolicyNumber = selectedSearchType === SEARCH_TYPE_POLICY_NUMBER;
      // const searchByBusinessName = selectedSearchType === SEARCH_TYPE_BUSINESS_NAME;
      // Default to calling MSE/MSC+ service if searching by policy number, no results are returned, and the search value is 10 characters (valid policy number)
      if (searchByPolicyNumber && results.data?.clPolicies.length === 0 && searchInputValue.trim().length === 10) {
        const msePolicySearch = await getMSEMSCPlusPolicyInfoAndRedirect(POLICY_SYSTEM_MSE, searchInputValue);
        // Display no results message if not found.
        if (msePolicySearch?.data?.length === 0) {
          setSearchResults((prevState) => ({
            ...prevState,
            loading: false,
            data: [],
          }));
        }
      }
      // Try to automatically perform action for user if only 1 result
      else if (searchByPolicyNumber && results.data?.clPolicies.length === 1) {
        resetGrid();
        if (results.data.clPolicies[0].sourceSystem === 'T') {
          getMSEMSCPlusPolicyInfoAndRedirect(POLICY_SYSTEM_MSE, results.data.clPolicies[0].policyNumber);
        } else if (results.data.clPolicies[0].sourceSystem === 'I') {
          setSelectedPolicyNumber(results.data.clPolicies[0].policyNumber);
        } else if (results.data.clPolicies[0].sourceSystem === null) {
          setPolicyNumberNotFound(ERROR_MESSAGE);
        }
      } else {
        setSearchResults((prevState) => ({
          ...prevState,
          loading: false,
          data: results.data?.clPolicies?.map((row) => ({ id: row.policyNumber, ...row })),
        }));
      }
    } catch (err) {
      console.error(err);
      setErrorGetPolicyInquiry(ERROR_MESSAGE);
      resetGrid();
    }
  };

  const handleClickSearch = async () => {
    resetGrid();

    resetAlerts();
    await retrieveResults();
  };

  const handleOnPageChange = (page) => {
    setPaginationData((prevState) => ({ ...prevState, currentPage: page }));
  };

  const handleOnPageSizeChange = (pageSize) => {
    setPaginationData((prevState) => ({ ...prevState, pageSize: pageSize }));
  };

  const handleClickViewPolicyOrDocuments = (redirectType) => {
    resetAlerts();
    setSearchResults((prevState) => ({ ...prevState, loading: true }));
    setSelectedPolicyNumber('');
    getMSSPolicyInfoAndRedirect(redirectType);
  };

  const getTextFieldLabel = () => {
    switch (selectedSearchType) {
      case SEARCH_TYPE_POLICY_NUMBER:
        return 'Policy Number';
      case SEARCH_TYPE_BUSINESS_NAME:
        return 'Business Name';
      default:
        return 'Enter Search Input';
    }
  };

  return (
    <>
      {errorGetPolicyInquiry && (
        <Box mb={2}>
          <Alert message={errorGetPolicyInquiry} severity="error" mb={2} />
        </Box>
      )}
      {policyInquiryRedirectError && (
        <Box>
          <Alert id="cls-policy-inquiry-error-alert-component" message={policyInquiryRedirectError} severity="error" />
        </Box>
      )}
      {policyNumberNotFound && (
        <Box mb={2}>
          <Alert message={policyNumberNotFound} severity="info" mb={2} />
        </Box>
      )}
      <RowCol>
        <Typography id="cls-policy-inquiry-title" variant="h6" gutterBottom={true}>
          Policy Inquiry
        </Typography>
      </RowCol>
      <RowCol id="cls-policy-inquiry-row-col-typgraphy" mb={2}>
        <Box id="cls-policy-inquiry-box-typgraphy-spacer" pt={2}>
          <RowCol>
            <Typography gutterBottom={true}>Enter the following to view policy information. What-if quoting for Main Street Station is available.</Typography>
          </RowCol>
          <Box mb={2} />
          <Row id="policy-system" mb={2}>
            <RowItem>
              <Select
                label="Specific Search"
                displayLabel="Select Search Type"
                onChange={handleChangeSearchType}
                menuItems={[
                  { label: 'Select Search Type', value: '', disabled: true },
                  { label: 'Policy Number', value: SEARCH_TYPE_POLICY_NUMBER },
                  { label: 'Business Name', value: SEARCH_TYPE_BUSINESS_NAME },
                ]}
                name="searchType"
                value={selectedSearchType}
              />
            </RowItem>
            <Box mr={2} />
            <RowItem>
              <TextField
                id="cls-textfield-policy-number"
                onChange={handleSearchInputChange}
                value={searchInputValue}
                label={getTextFieldLabel()}
                data-testid="textfield-policy-number"
              />
            </RowItem>
          </Row>

          <Modal
            open={!!selectedPolicyNumber}
            onClose={() => setSelectedPolicyNumber('')}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Paper style={{ padding: `1rem` }}>
              <Fade in={!!selectedPolicyNumber}>
                <RowCol>
                  <Box p={1}>
                    <Typography variant="h5">View Policy Details or Documents</Typography>
                  </Box>
                  <Box p={1}>
                    <Typography variant="secondary">
                      View policy information or create a what-if quote within Main Street Station by clicking Search. All policy changes should be sent to
                      <Button id={'cls-acord-change-with-email'} href={MAIL_TO_LINK} color={'primary'} style={{ textTransform: 'none' }} variant="text">
                        MSACLProcessing@msagroup.com
                      </Button>
                    </Typography>
                  </Box>
                  <Box p={1}>
                    <DatePicker
                      id="cls-policy-inquiry-policy-as-of-date"
                      onChange={handleOnChangePolicyAsOfDate}
                      value={policyAsOfDate}
                      label="View Policy As Of"
                      name="policyAsOfDate"
                    />
                  </Box>
                  <Box p={1} />
                  <Box p={1} display="flex" flexDirection="row">
                    <Button
                      id="cls-policy-inquiry-view-policy-docs"
                      onClick={() => handleClickViewPolicyOrDocuments(MSS_POLICY_INQUIRY)}
                      variant="contained"
                      color="primary">
                      view policy details
                    </Button>
                    <Box p={1} />
                    <Button
                      id="cls-policy-inquiry-view-policy-docs"
                      onClick={() => handleClickViewPolicyOrDocuments(MSS_VIEW_POLICY_DOCUMENTS)}
                      color="secondary"
                      variant="text">
                      view policy documents
                    </Button>
                  </Box>
                </RowCol>
              </Fade>
            </Paper>
          </Modal>

          <Box>
            <RowCol mb={2} />
            <Row id="policy-system" mb={2}>
              <Button
                id="cls-policy-inquiry-search-button"
                disabled={searchInputValue.length < 2 || searchInputValue.length > 25 || selectedSearchType === '' || searchResults.loading}
                onClick={handleClickSearch}
                variant="contained"
                color="primary">
                Search
              </Button>
            </Row>
          </Box>
          {searchResults?.data !== null && (
            <>
              <Box my={3}>{searchResults?.data.length === 0 && <Divider />} </Box>
              <DataGrid
                columns={clSearchResultColumns}
                loading={searchResults.loading}
                onPageChange={handleOnPageChange}
                onPageSizeChange={handleOnPageSizeChange}
                page={paginationData.currentPage}
                pageSize={paginationData.pageSize}
                paginationMode="client"
                rowCount={searchResults?.data?.length}
                rows={searchResults.data}
                noResultsMessage={`No results found. Adjust your search${
                  selectedSearchType === SEARCH_TYPE_BUSINESS_NAME ? ' or try entering a policy number' : ''
                }.`}
              />
            </>
          )}
        </Box>
      </RowCol>
    </>
  );
};
export default CLSPolicyInquiry;
